import { BatchItem } from "../models/active-batches/batch-item";
import { MessageBase } from "./message-base";

export enum BatchState  {    
      STARTED = 'STARTED',
      TEMP_STARTED = 'TEMP_STARTED',
      ENDED = 'ENDED',
     // TEMP_ENDED = 'TEMP_ENDED', not used.. used ENDED instead.
      CANCELED = 'CANCELED',
      TEMP_CANCELED = 'TEMP_CANCELED',
      CREATING = 'CREATING'
  }

  export enum MessageTypes {
      BATCH_CRUD = "BATCH_CRUD",
      ADD_BATCH_ITEM = "ADD_BATCH_ITEM",
      DELETE_BATCH_ITEM = "DELETE_BATCH_ITEM",   
      CUTTING_TICKET = "CUTTING_TICKET" 
}

export class CreatedBatchDetails {
      constructor( image: string, createdBy: string, frameStyleId: string, frameStyle: string, createdDateTime: string, isRecut: boolean, recutFrameSerialId?: string, recutFrameSize?: string, recutFrameYear?: string ) {
            this.image = image;
            this.createdBy = createdBy
            this.frameStyleId = frameStyleId;
            this.frameStyle = frameStyle;
            this.createdDateTime = createdDateTime;
            this.isRecut = isRecut;
            this.recutFrameSerialId = recutFrameSerialId;
            this.recutFrameSize = recutFrameSize; 
            this.recutFrameYear = recutFrameYear;
      }
      image: string;
      createdBy: string;
      frameStyleId: string;
      frameStyle: string;
      createdDateTime: string;
      isRecut: boolean;
      recutFrameSerialId?: string;
      recutFrameSize?: string;
      recutFrameYear?: string;
}

export class CuttingStationBatchMessage  extends MessageBase {
     
     constructor( batchState: BatchState, batchId: string, batchDetails?: CreatedBatchDetails, batchSerialIds?: string[]) {
           super( MessageTypes.BATCH_CRUD )
           this.batchState = batchState.valueOf();
           this.batchId = batchId;
           this.batchDetails = batchDetails;
           this.batchSerialIds = batchSerialIds;
     }
     batchState: string
     batchId: string
     batchDetails?: CreatedBatchDetails
     batchSerialIds?: string[];
     
}



export class CuttingStationBatchItemMessage extends MessageBase {

      constructor( messageType: MessageTypes, batchId: string, productId: string, frameStyleId: string, frame: any, curStationId: number, batchItem?: BatchItem, ) {
            super(messageType)
            this.batchId = batchId;
            this.productId = productId;
            this.frameStyleId = frameStyleId;
            this.frame = frame;
            this.curStationId = curStationId;
            this.batchItem = batchItem;
      }

      batchId: string;
      productId: string;
      frameStyleId?: string;
      batchItem?:BatchItem;
      frame: any;
      curStationId: number;
}

export class CuttingTicketMessage extends MessageBase {
      constructor( messageType: MessageTypes, quantity: number, priority: string, orderSourceId: string ) {
            super(messageType);
            this.quantity = quantity;
            this.priority = priority;
            this.orderSourceId = orderSourceId; 
      }

      quantity: number;
      priority: string;
      orderSourceId: string; 
}