
/*
let url = URL ( string: "\(Config.baseUrl())/api/scanner/history-event/\(qrCode)")!
let apiRequest = APIRequest( url: url )
let result: GetFrameHistoryResponse = try await  apiRequest.getAsync()
*/

import { Card } from 'primereact/card';
import { FrameHistoryEntry } from './models/frame-history-event.model';
import { StationsEnum } from './models/stations-enum';
import { DateUtils } from '../utils/date-utils';
import { HistoryEventTypes } from './models/history-event-types';

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/

interface IFrameHistory extends FrameHistoryEntry {

}

//(props: IDailyActivityReportProps) => {
const FrameHistoryEntryTimeLineView = ( props: IFrameHistory) => { 

 
      return (
            <>
            { props.eventTypeId in HistoryEventTypes ?
                  <Card title={<div className="flex flex-row">{props.station} - <div className='text-gray-400 text-1xl'> &nbsp;{ HistoryEventTypes[props.eventTypeId].replaceAll('_', ' ')}</div></div>} 
                                    subTitle={ <div className="font-italic">{props.userName}</div> } style={{marginBottom: '15px'}} >

                        {props.duration !== -1 ? `${DateUtils.toHHMMSS_string(props.duration)}` : ""}
                        { props.rejectedComment  &&
                              <div className='flex flex-column p-2' style={{border:"1px solid lightblue", backgroundColor: "#f7f7fc"}}>
                                    <div className='text-blue-700 text-xl font-semibold'>QC issue identified.  Sent for correction { props.rejectedToStationId && props.rejectedToStationId !== -1 ? `at ${StationsEnum.toDisplayName(props.rejectedToStationId.toString())}` : "" }</div>
                                    <div className='flex flex-row p-2 pt-3 vertical-align-bottom'><div className='font-semibold text-gray-700 mr-1' >Reason: </div>{ props.rejectedReason}</div> 
                                    <div className='flex flex-row p-2'><div className='font-semibold text-gray-700 mr-1'>Comment: </div>{ props.rejectedComment}</div>

                              </div>
                        }
                  </Card>
                  : 
                  <Card title="MISSING EVENT TYPE">                   
                        <div> ERROR: HistoryEventTypes is missing event type { props.eventTypeId } </div>
                  </Card>
            }
            </>
      )
}

export default FrameHistoryEntryTimeLineView;
